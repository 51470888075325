<template>
  <!--begin::Access Point-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ sigurAccessPoint.name }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ floor.office.name }} / {{ floor.name }}</span>
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isLoading" class="card-body">
      <div class="row">
        <h5 class="col-md-9 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.EMPLOYEE_GROUPS_TITLE") }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">
            {{ $t("MANAGEMENT.EMPLOYEE_GROUPS_DESC") }}
          </span>
        </h5>
        <div class="col-md-3 card-toolbar">
          <!--begin::Button-->
          <b-button v-if="isCanEdit"
                    v-b-modal.modal-add-access-point-group
                    class="btn bg-light-primary font-weight-bolder font-size-sm float-right">
            <i class="fas fa-plus icon-md"></i>{{ $t("MANAGEMENT.ADD_GROUP_BUTTON") }}
          </b-button>
          <!--end::Button-->
        </div>
      </div>
      <template v-if="sigurAccessPoint.groups.length > 0 || notFound">
        <!--begin::Table-->
        <div class="table-responsive">
          <b-table
              id="groups-table"
              class="table-vertical-center"
              show-empty
              :items="sigurAccessPoint.groups"
              :fields="fields">
            <template #empty="scope">
              <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
            </template>
            <template #head()="data">
              <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
            </template>
            <template #head(actions)="data">
              <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
            </template>
            <template #cell(name)="data">
              <p class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg mt-2 mb-0">{{ data.item.name }}</p>
            </template>
            <template #cell(actions)="data">
              <span v-if="isCanEdit" class="float-right">
                <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"
                          @click="onDeleteGroup(data.item)"
                          v-b-modal.modal-delete-access-point-group
                          v-b-tooltip.hover.v-dark="$t('MANAGEMENT.DELETE_TOOLTIP_TEXT')">
                  <Trash/>
                </b-button>
              </span>
            </template>
          </b-table>
        </div>
        <!--end::Table-->
      </template>
      <!--begin::Dummy-->
      <template v-if="sigurAccessPoint.groups.length === 0 && !notFound">
        <div class="row">
          <div class="col-md-6 alert alert-custom alert-light-warning fade show ml-4 my-5" role="alert">
            <div class="alert-icon"><Warning/></div>
            <div class="alert-text text-secondary">{{ $t("MANAGEMENT.EMPLOYEE_GROUPS_NOT_YET") }}</div>
          </div>
        </div>
      </template>
      <!--end::Dummy-->
    </div>
    <!--end::Body-->
    <!--begin::Add Access Point Group Modal-->
    <AddAccessPointGroupModal
        :selectedGroups="getSelectedGroups"/>
    <!--end::Add Access Point Group Modal-->
    <!--begin::Delete Access Point Group Modal-->
    <b-modal
        id="modal-delete-access-point-group"
        centered>
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_GROUPS_TITLE") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_GROUPS_DESC") }}</h5>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="danger"
                @click="handleDelete">
              {{ $t("MANAGEMENT.DELETE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--end::Delete Access Point Group Modal-->
  </div>
  <!--end::Access Point-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_SIGUR_POINT, REMOVE_ACCESS_POINT_GROUP } from "@/core/services/store/management/integrations.module";
import { GET_FLOOR } from "@/core/services/store/management.module";

import AddAccessPointGroupModal from "@/view/layout/modal/AddAccessPointGroupModal";
import Trash from "@/view/layout/svg/Trash";
import Warning from "@/view/layout/svg/Warning";

export default {
  name: "AccessPoint",
  components: {
    AddAccessPointGroupModal,
    Trash,
    Warning
  },
  mounted() {
    this.$store.dispatch(GET_SIGUR_POINT, this.$route.params.id).then(res => {
      this.$store.dispatch(GET_FLOOR, res.floor.id);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Sigur", route: "/management/sigur#access" },
        { title: `${this.sigurAccessPoint.name}` }
      ]);
      this.isLoading = true;
    });
  },
  data() {
    return {
      isLoading: false,
      notFound: false,
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.GROUP_TABLE_TITLE") },
        { key: 'actions', label: this.$t("MANAGEMENT.ACTIONS_TABLE_TITLE") }
      ],
      deleteFormId: null
    }
  },
  watch: {},
  computed: {
    ...mapGetters([
      "sigurAccessPoint",
      "floor"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    },
    getSelectedGroups() {
      if (this.sigurAccessPoint.groups) {
        return this.sigurAccessPoint.groups.map(item => item.name);
      }
    }
  },
  methods: {
    onDeleteGroup(item) {
      this.deleteFormId = item.id;
    },
    resetModal() {
      this.deleteFormId = null;
      this.$bvModal.hide('modal-delete-access-point-group');
    },
    handleDelete() {
      const body = {
        group_id: this.deleteFormId
      }
      this.$store.dispatch(REMOVE_ACCESS_POINT_GROUP, { id: this.$route.params.id, body })
          .then(() => {
            this.$store.dispatch(GET_SIGUR_POINT, this.$route.params.id)
            this.deleteFormId = null;
            this.$bvModal.hide('modal-delete-access-point-group');
          });
    }
  }
}
</script>

<style scoped>

</style>
