<template>
  <b-modal
      id="modal-add-access-point-group"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.ADD_GROUPS_TITLE") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label-for="accepted-groups-input"
          :invalid-feedback="$t('MANAGEMENT.GROUPS_SELECT_INVALID_FEEDBACK')"
          :state="groupsState">
        <template slot="label">{{ $t("MANAGEMENT.GROUPS_SELECT_TITLE") }}</template>
        <treeselect v-model="selectedGroups"
                    id="accepted-groups-input"
                    :placeholder="$t('MANAGEMENT.GROUPS_SELECT_PLACEHOLDER')"
                    :multiple="true"
                    :normalizer="normalizer"
                    :state="groupsState"
                    :clearValueText="$t('SELECT.CLEAR_FIELD')"
                    :searchPromptText="$t('MANAGEMENT.GROUPS_SELECT_SEARCH')"
                    :async="true"
                    :load-options="loadOptions">
<!--     Выбрать группы...     :options="groupsList"-->
          <div slot="value-label" slot-scope="{ node }">{{ node.raw.name ? node.raw.name : node.raw.id }}</div>
        </treeselect>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ $t("MANAGEMENT.ADD_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_GROUPS_LIST } from "@/core/services/store/management.module";
import { ADD_ACCESS_POINT_GROUP, GET_SIGUR_POINT } from "@/core/services/store/management/integrations.module";

import DropdownSearch from "@/view/layout/extras/DropdownSearch";
import Trash from "@/view/layout/svg/Trash";

export default {
  name: "AddAccessPointGroupModal",
  components: {
    DropdownSearch,
    Trash
  },
  mounted() {
    // this.$store.dispatch(GET_GROUPS_LIST, { limit: 500 });
  },
  props: {
    selectedGroups: {
      type: Array
    }
  },
  data() {
    return {
      groupsState: null,
      normalizer(node) {
        return {
          id: node.name,
          label: node.name,
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      "groupsList"
    ]),
  },
  methods: {
    loadOptions({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.$store.dispatch(GET_GROUPS_LIST, { name: searchQuery })
            .then(res => callback(null, res.items));
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.selectedGroups.length > 0 ? this.groupsState = true : this.groupsState = false;
      return valid;
    },
    resetModal() {
      this.groupsState = null;
      this.$bvModal.hide('modal-add-access-point-group');
    },
    addGroupId(id) {
      const body = {
        group_ids: [id]
      }
      this.$store.dispatch(ADD_ACCESS_POINT_GROUP, { id: this.$route.params.id, body })
          .then(() => {
            this.$store.dispatch(GET_SIGUR_POINT, this.$route.params.id);
            this.$bvModal.hide('modal-add-access-point-group');
          });
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.selectedGroups.forEach(item => {
        this.$store.dispatch(GET_GROUPS_LIST, { name: item })
            .then(res => this.addGroupId(res.items[0].id))
      })
    }
  }
}
</script>

<style scoped>

</style>
